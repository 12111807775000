import styled from 'styled-components'

import { Box, duration, Text, Tooltip } from '@cutover/react-ui'
import { MrdDashboardWidget } from '../../account/mrd-dashboard-widget'
import { TaskAttributeWastageComponent } from '../../types'

type TaskAttributeWastageWidgetProps = {
  data: TaskAttributeWastageComponent
}

export const TaskAttributeWastageWidget = ({ data }: TaskAttributeWastageWidgetProps) => {
  const { name } = data

  return (
    <MrdDashboardWidget title={name} fullWidth>
      <WidgetContent data={data} />
    </MrdDashboardWidget>
  )
}

const WidgetContent = ({ data }: { data: TaskAttributeWastageComponent }) => {
  return (
    <Box>
      {data.notes && (
        <Text color="text-light" size="small">
          {data.notes}
        </Text>
      )}
      {data.errors && (
        <Text color="error" size="small">
          {data.errors}
        </Text>
      )}
      {data.values.length > 0 &&
        data.values.map(value => {
          return (
            <Box direction="row" align="center" margin={{ top: '10px' }} key={value.id} gap="10px">
              <Box direction="row" justify="between" align="center" css="flex-grow: 1; flex-basis: 0;">
                <Text color="text-light">{value.name}</Text>
                <Text color="text-light">{duration(value.start_lateness, 5)}</Text>
              </Box>
              <ProgressBar>
                {value.values
                  .filter(v => v.count > 0)
                  .map(v => {
                    const percent = `${Math.round((v.count / value.task_count) * 100)}%`
                    const dataType = v.count > 1 ? 'tasks wastage' : 'task wastage'
                    const tooltipContent = `${v.count} ${dataType} ${v.name} (${percent})`

                    return (
                      <ProgressBarItem
                        background={v.color}
                        basis={percent}
                        key={`${value.id}-${v.name}`}
                        data-testid="progress-bar-item"
                      >
                        <Tooltip content={tooltipContent} placement="top" hasCursor={false}>
                          <ProgressBarItemContent>{percent}</ProgressBarItemContent>
                        </Tooltip>
                      </ProgressBarItem>
                    )
                  })}
              </ProgressBar>
            </Box>
          )
        })}
    </Box>
  )
}

const ProgressBar = styled(Box)`
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 60px;
  overflow: hidden;
  width: 100%;
  height: 16px;
  border-radius: 16px;
  gap: 1px;
`

const ProgressBarItem = styled(Box)`
  justify-content: center;
  padding-right: 3px;
  opacity: 0.8;
  transition: all 0.4s cubic-bezier(0.35, 0, 0.25, 1);
  &:hover {
    opacity: 1;
  }
`

const ProgressBarItemContent = styled(Box)`
  text-align: right;
  color: white;
  font-size: 12px;
  line-height: 16px;
`
