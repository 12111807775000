import { Placement } from '@floating-ui/react'

import { Button } from '../button'
import { Box } from '../../layout/box'
import { Tooltip } from '../../overlays/tooltip'
import { themeColor } from '../../theme'

type FavoriteStarProps = {
  isFavorite: boolean
  toggleFavorite?: () => void
  tooltip?: {
    addFavorite: string
    removeFavorite: string
    placement?: Placement
  }
  isDisabled?: boolean
  'data-testid'?: string
}

export const FavoriteStar = ({ isFavorite, tooltip, ...props }: FavoriteStarProps) => {
  if (tooltip) {
    const { addFavorite, removeFavorite, placement } = tooltip

    return (
      <Tooltip content={isFavorite ? removeFavorite : addFavorite} placement={placement}>
        <FavoriteStarInner isFavorite={isFavorite} {...props} />
      </Tooltip>
    )
  }

  return <FavoriteStarInner isFavorite={isFavorite} {...props} />
}

const FavoriteStarInner = ({ isFavorite, 'data-testid': testId, toggleFavorite, isDisabled }: FavoriteStarProps) => {
  return (
    <Box height="32px" width="32px" align="center" justify="center">
      <Button
        plain
        icon={isFavorite ? 'star-filled' : 'star'}
        onClick={toggleFavorite}
        disabled={isDisabled}
        data-testid={testId}
        a11yTitle={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
        css={`
          cursor: ${isDisabled || !toggleFavorite ? 'default' : 'pointer'};

          svg {
            height: 20px;
            width: 20px;
            fill: ${isFavorite ? themeColor('star') : themeColor('text-light')};
          }
          &:hover {
            svg {
              fill: ${isDisabled ? undefined : themeColor('star')};
            }
          }
        `}
      />
    </Box>
  )
}
